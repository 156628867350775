import React, { lazy, Suspense } from "react";
import Header from "./components/Header";
import BottomNav from "./components/BottomNav";
import ChristmasBottomNav from "./components/ChristmasBottomNav";
import { Navigate } from "react-router-dom";
import Snow from "./components/Snow";
import ChristmasLights from "./components/ChristmasLights";
import { getCurrentHolidays, HOLIDAY_CONFIGS } from "./utils/holidays";

const DEV_AUTHORIZED_USERS = ["admin", "pblang"]; // Add your authorized usernames

const HOLIDAY_NAV_COMPONENTS = {
  ChristmasBottomNav,
  
};

const Layout = ({
  children,
  username,
  onLogout,
  hideBottomNav,
  avatarUrl,
  updateAvatarUrl,
  hasSubmittedAllPicks,
}) => {
  const isDevAuthorized = DEV_AUTHORIZED_USERS.includes(username);
  const currentHolidays = getCurrentHolidays();

  // Get the appropriate navigation component based on current holiday
  const NavigationComponent = (() => {
    if (currentHolidays.length > 0) {
      const holidayNav = currentHolidays[0].bottomNavComponent;
      return HOLIDAY_NAV_COMPONENTS[holidayNav] || BottomNav;
    }
    return BottomNav;
  })();

  // Check if Christmas or Christmas Season is active for snow effect
  const isChristmasThemed = currentHolidays.some(
    (holiday) =>
      holiday.name === HOLIDAY_CONFIGS.CHRISTMAS_SEASON.name ||
      holiday.name === HOLIDAY_CONFIGS.CHRISTMAS.name
  );

  // Determine if we're on the home page and picks are submitted
  const isHomePageWithSubmittedPicks =
    window.location.pathname === "/home" &&
    !hideBottomNav && // We use hideBottomNav as a proxy for editing mode
    hasSubmittedAllPicks;

  return (
    <div className="App min-h-screen flex flex-col bg-gray-900 relative">
      {isChristmasThemed && <Snow />}
      <Header
        userName={username}
        onLogout={onLogout}
        avatarUrl={avatarUrl}
        updateAvatarUrl={updateAvatarUrl}
        showPicksWarning={!hasSubmittedAllPicks}
        className="fixed top-0 left-0 right-0 z-50"
      />
      {isChristmasThemed && (
        <div
          className="fixed top-[72px] left-0 right-0 z-30"
          style={{
            paddingTop: "env(safe-area-inset-top)",
            position: "fixed",
            left: 0,
            right: 0,
            zIndex: 30,
            pointerEvents: "none",
          }}
        >
          <ChristmasLights />
        </div>
      )}

      <main
        className={`flex-1 mt-24 pb-24 px-4 relative z-10 ${
          isHomePageWithSubmittedPicks ? "overflow-hidden" : "overflow-y-auto"
        }`}
      >
        <div className="container mx-auto max-w-4xl">
          {window.location.pathname === "/dev" && !isDevAuthorized ? (
            <Navigate to="/home" replace />
          ) : (
            children
          )}
        </div>
      </main>
      {!hideBottomNav && (
        <Suspense fallback={<BottomNav className="fixed bottom-0 left-0 right-0 z-50" />}>
          <NavigationComponent
            className="fixed bottom-0 left-0 right-0 z-50"
            isDevAuthorized={isDevAuthorized}
          />
        </Suspense>
      )}
    </div>
  );
};

export default Layout;
