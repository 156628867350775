import { getLatestAvailableWeek } from "./components/weekDates";
import { weeklyGames } from "./weeklyGamesData";


const USE_TEST_DATA = false; // Set this to false when you want to use live data

// Add mock test data
const testData = {
  6: [
    {
      home: {
        abbreviation: "ATL",
        name: "Atlanta Falcons",
        spread: -1.5,
      },
      away: {
        abbreviation: "TB",
        name: "Tampa Bay Buccaneers",
        spread: 1.5,
      },
      winner: "NULL",
      startTime: "2024-10-03T20:15:00-04:00",
    },
    {
      home: {
        abbreviation: "MIN",
        name: "Minnesota Vikings",
        spread: -2.5,
      },
      away: {
        abbreviation: "NYJ",
        name: "New York Jets",
        spread: 2.5,
      },
      winner: "NULL",
      startTime: "2024-10-06T09:30:00-04:00",
    },
    {
      home: {
        abbreviation: "NE",
        name: "New England Patriots",
        spread: -1,
      },
      away: {
        abbreviation: "MIA",
        name: "Miami Dolphins",
        spread: 1,
      },
      winner: "NULL",
      startTime: "2024-10-06T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "CHI",
        name: "Chicago Bears",
        spread: -3.5,
      },
      away: {
        abbreviation: "CAR",
        name: "Carolina Panthers",
        spread: 3.5,
      },
      winner: "NULL",
      startTime: "2024-10-06T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "JAX",
        name: "Jacksonville Jaguars",
        spread: -3,
      },
      away: {
        abbreviation: "IND",
        name: "Indianapolis Colts",
        spread: 3,
      },
      winner: "NULL",
      startTime: "2024-10-06T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "HOU",
        name: "Houston Texans",
        spread: 1,
      },
      away: {
        abbreviation: "BUF",
        name: "Buffalo Bills",
        spread: -1,
      },
      winner: "NULL",
      startTime: "2024-10-06T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "CIN",
        name: "Cincinnati Bengals",
        spread: 2.5,
      },
      away: {
        abbreviation: "BAL",
        name: "Baltimore Ravens",
        spread: -2.5,
      },
      winner: "NULL",
      startTime: "2024-10-06T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "WSH",
        name: "Washington Commanders",
        spread: -3,
      },
      away: {
        abbreviation: "CLE",
        name: "Cleveland Browns",
        spread: 3,
      },
      winner: "NULL",
      startTime: "2024-10-06T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "DEN",
        name: "Denver Broncos",
        spread: -3,
      },
      away: {
        abbreviation: "LV",
        name: "Las Vegas Raiders",
        spread: 3,
      },
      winner: "NULL",
      startTime: "2024-10-06T16:05:00-04:00",
    },
    {
      home: {
        abbreviation: "SF",
        name: "San Francisco 49ers",
        spread: -7.5,
      },
      away: {
        abbreviation: "ARI",
        name: "Arizona Cardinals",
        spread: 7.5,
      },
      winner: "NULL",
      startTime: "2024-10-06T16:05:00-04:00",
    },
    {
      home: {
        abbreviation: "SEA",
        name: "Seattle Seahawks",
        spread: -6,
      },
      away: {
        abbreviation: "NYG",
        name: "New York Giants",
        spread: 6,
      },
      winner: "NULL",
      startTime: "2024-10-06T16:25:00-04:00",
    },
  ],
};

export const currentWeek = getLatestAvailableWeek();

// Add cache for scoreboard requests
const scoreboardCache = {
  data: new Map(),
  timestamps: new Map(),
  pendingRequests: new Map(),
};

const CACHE_DURATION = 30000; // 30 seconds

export async function updateScores(week, forceAllWeeks = false) {
  if (USE_TEST_DATA) {
    weeklyGames[week] = testData[week];
    return;
  }

  // If forceAllWeeks is true, fetch all weeks up to the current week
  const weeksToFetch = forceAllWeeks
    ? Array.from({ length: currentWeek }, (_, i) => i + 1)
    : [week];

  try {
    // Check cache and handle pending requests for each week
    await Promise.all(
      weeksToFetch.map(async (weekNum) => {
        // Skip if no games data exists for this week
        if (!weeklyGames[weekNum]) {
          console.warn(`No games data available for week ${weekNum}`);
          return;
        }

        const cacheKey = `scoreboard_${weekNum}`;
        const now = Date.now();

        // If there's a pending request for this week, wait for it
        if (scoreboardCache.pendingRequests.has(cacheKey)) {
          return scoreboardCache.pendingRequests.get(cacheKey);
        }

        // Check if we have cached data that's still valid
        const cachedTimestamp = scoreboardCache.timestamps.get(cacheKey);
        if (cachedTimestamp && now - cachedTimestamp < CACHE_DURATION) {
          return scoreboardCache.data.get(cacheKey);
        }

        // Create a new request promise
        const requestPromise = (async () => {
          const url = `https://site.api.espn.com/apis/site/v2/sports/football/nfl/scoreboard?week=${weekNum}`;
          const response = await fetch(url);
          const data = await response.json();
          const games = data.events;

          // Process the games data
          games.forEach((game) => {
            const gameId = game.id;

            const homeTeam = game.competitions[0].competitors.find(
              (c) => c.homeAway === "home"
            );
            const awayTeam = game.competitions[0].competitors.find(
              (c) => c.homeAway === "away"
            );

            if (!homeTeam || !awayTeam) {
              console.warn(`Missing team data for game ${gameId}`);
              return;
            }

            const homeAbbr = homeTeam.team.abbreviation;
            const awayAbbr = awayTeam.team.abbreviation;
            const homeShortName = homeTeam.team.shortDisplayName;
            const awayShortName = awayTeam.team.shortDisplayName;
            const homeScore = homeTeam.score;
            const awayScore = awayTeam.score;
            const clock = game.status.displayClock;
            const period = game.status.period;
            const lastPlay = game.competitions[0].situation
              ? game.competitions[0].situation.lastPlay.text
              : "No last play info";

            const downDistance = game.competitions[0].situation
              ? game.competitions[0].situation.downDistanceText
              : "No down and distance info";

            const possessionTeamId = game.competitions[0].situation
              ? game.competitions[0].situation.possession
              : null;
            const possessionTeam = game.competitions[0].competitors.find(
              (team) => team.team.id === possessionTeamId
            );

            const possession = possessionTeam
              ? possessionTeam.team.abbreviation
              : "No possession info";

            // Add this code to fetch and store the overall record
            const homeRecord =
              homeTeam.records.find(
                (r) => r.type === "total" || r.type === "overall"
              )?.summary || "N/A";
            const awayRecord =
              awayTeam.records.find(
                (r) => r.type === "total" || r.type === "overall"
              )?.summary || "N/A";

            // Find the corresponding game in weeklyGames and update the score and game status
            const weekGame = weeklyGames[weekNum]?.find(
              (g) =>
                (g.home.abbreviation === homeAbbr ||
                  g.home.abbreviation === homeTeam.team.location) &&
                (g.away.abbreviation === awayAbbr ||
                  g.away.abbreviation === awayTeam.team.location)
            );

            if (!weekGame) {
              console.warn(`Game not found: ${awayAbbr} vs ${homeAbbr}`);
              return;
            }

            weekGame.homeScore = parseInt(homeScore);
            weekGame.awayScore = parseInt(awayScore);
            weekGame.clock = clock;
            weekGame.period = period;
            weekGame.lastPlay = lastPlay;
            weekGame.downDistance = downDistance;
            weekGame.possession = possession;
            weekGame.home.logo = homeTeam.team.logo;
            weekGame.away.logo = awayTeam.team.logo;
            weekGame.home.record = homeRecord;
            weekGame.away.record = awayRecord;
            weekGame.home.shortName = homeShortName;
            weekGame.away.shortName = awayShortName;
            weekGame.gameId = gameId;
            weekGame.status = game.status.type.name;

            // Determine winner based on spread
            if (game.status.type.completed) {
              let homeSpread = weekGame.home.spread;
              let awaySpread = weekGame.away.spread;

              const isEven = homeSpread === "E" || homeSpread === "Even";
              const scoreDifference = weekGame.homeScore - weekGame.awayScore;

              if (isEven) {
                if (scoreDifference > 0) {
                  weekGame.winner = `${weekGame.home.abbreviation} (E)`;
                } else if (scoreDifference < 0) {
                  weekGame.winner = `${weekGame.away.abbreviation} (E)`;
                } else {
                  weekGame.winner = "PUSH";
                }
              } else {
                homeSpread = parseFloat(homeSpread);
                awaySpread = parseFloat(awaySpread);

                if (scoreDifference + homeSpread > 0) {
                  const formattedSpread =
                    homeSpread > 0 ? `+${homeSpread}` : homeSpread;
                  weekGame.winner = `${weekGame.home.abbreviation} (${formattedSpread})`;
                } else if (scoreDifference + homeSpread < 0) {
                  const formattedSpread =
                    awaySpread > 0 ? `+${awaySpread}` : awaySpread;
                  weekGame.winner = `${weekGame.away.abbreviation} (${formattedSpread})`;
                } else {
                  weekGame.winner = "PUSH";
                }
              }
            }
          });

          // Update cache
          scoreboardCache.data.set(cacheKey, data);
          scoreboardCache.timestamps.set(cacheKey, now);
          scoreboardCache.pendingRequests.delete(cacheKey);
        })();

        // Store the pending request
        scoreboardCache.pendingRequests.set(cacheKey, requestPromise);
        return requestPromise;
      })
    );
  } catch (error) {
    console.error("Error updating scores:", error);
    throw error;
  }
}

// Add cache cleanup
setInterval(() => {
  const now = Date.now();
  for (const [key, timestamp] of scoreboardCache.timestamps.entries()) {
    if (now - timestamp > CACHE_DURATION) {
      scoreboardCache.data.delete(key);
      scoreboardCache.timestamps.delete(key);
    }
  }
}, CACHE_DURATION);

// Modified function to get games for a week, now including scores
export const getGamesForWeek = (week) => {
  if (!weeklyGames[week]) {
    console.warn(`No games found for week ${week}`);
    return [];
  }
  return weeklyGames[week];
};

// Add a new function to help with debugging
export const debugGameMatching = (week) => {
  if (!weeklyGames[week]) {
    console.warn(`No games found for week ${week}`);
    return;
  }

  console.log(
    `Games for week ${week}:`,
    weeklyGames[week].map((game) => ({
      home: game.home.abbreviation,
      away: game.away.abbreviation,
    }))
  );
};

// Updated function to get winners for a specific week
export const getWinnersForWeek = (week) => {
  const games = weeklyGames[week] || [];
  return games.map((game) => ({
    winner: game.winner,
    home: game.home.abbreviation,
    away: game.away.abbreviation,
  }));
};

// Updated function to check if a pick is correct
export const isPickCorrect = (pick, gameResult) => {
  if (!pick || !gameResult || gameResult.winner === "NULL" || gameResult.winner === "PUSH") {
    return false;
  }

  // Parse the pick to extract team and spread
  const [pickedTeam, pickedSpread] = pick.split(" ");
  
  // Parse the winner to extract team and spread
  const [winningTeam, winningSpread] = gameResult.winner.split(" ");

  // First compare the teams
  if (pickedTeam !== winningTeam) {
    return false;
  }

  // If spreads are different (accounting for formatting differences), return false
  const normalizeSpread = (spread) => {
    if (!spread) return "";
    spread = spread.replace(/[()]/g, "");
    // Handle "E" or "Even" cases
    if (spread === "E" || spread === "Even") return "E";
    // Ensure + prefix for positive spreads
    return parseFloat(spread) > 0 ? `+${parseFloat(spread)}` : `${parseFloat(spread)}`;
  };

  const normalizedPickSpread = normalizeSpread(pickedSpread);
  const normalizedWinSpread = normalizeSpread(winningSpread);

  return normalizedPickSpread === normalizedWinSpread;
};

// New function to check if a game has started
export const hasGameStarted = (gameStartTime) => {
  const now = new Date();
  return now >= new Date(gameStartTime);
};

export const hasGameFinished = (gameResult) => {
  return getGameStatus(gameResult) === GAME_STATUS.COMPLETED;
};

// Modify this existing function
export const hasLastGameStarted = (week) => {
  const games = getGamesForWeek(week);
  if (games.length === 0) return false;

  const lastGame = games[games.length - 1];
  return hasGameStarted(lastGame.startTime);
};

// Add this new function
export const hasLastGameFinished = (week) => {
  const games = getGamesForWeek(week);
  if (games.length === 0) return false;

  const lastGame = games[games.length - 1];
  return hasGameFinished(lastGame);
};

export const getMondayNightScore = (week) => {
  const games = weeklyGames[week] || [];
  if (games.length === 0) return null;

  const mondayNightGame = games[games.length - 1]; // Last game of the week

  if (mondayNightGame && mondayNightGame.winner !== "NULL") {
    return mondayNightGame.homeScore + mondayNightGame.awayScore;
  }

  return null; // Return null if the game hasn't finished or doesn't exist
};

export const GAME_STATUS = {
  UPCOMING: "upcoming",
  LIVE: "live",
  COMPLETED: "completed",
};

// New function to get game status
export const getGameStatus = (game) => {
  const now = new Date();
  const gameTime = new Date(game.startTime);

  // If the game has a winner, it's completed
  if (game.winner && game.winner !== "NULL") {
    return GAME_STATUS.COMPLETED;
  }

  // If the game hasn't started yet
  if (now < gameTime) {
    return GAME_STATUS.UPCOMING;
  }

  // If the game is in the 4th quarter and clock is at 0:00, it's completed
  if (game.period === 4 && game.clock === "0:00") {
    return GAME_STATUS.COMPLETED;
  }

  // If we have period and clock info, the game is live
  if (game.period && game.clock) {
    return GAME_STATUS.LIVE;
  }

  // Default to upcoming if we can't determine status
  return GAME_STATUS.UPCOMING;
};